@mixin analysisButton {
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 15px;
  padding-right: 5px;
  border-radius: 25px;
  color: white;
}

.analyze-landing-page {
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__header {
    font-size: 30px;
    height: 60px;
    padding-bottom: 10px;
    border-bottom: 3px solid black;
    width: 350px;
    display: flex;
    justify-content: center;
  }

  &__analyze-image {
    margin-top: 20px;
    width: 400px;
    height: 100%;
  }

  &__desc-text {
    margin-top: 25px;
    font-size: 24px;
  }

  &__buttons-block {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    width: 560px;
    font-size: 22px;

    .used-to-think-button {
      height: fit-content;
      @include analysisButton;
      background-color: #b70f0a;

      &:hover {
        cursor: pointer;
      }

      &__text {
        display: flex;
        flex-direction: column;

        &__first-line {
          display: flex;

          p {
            margin-left: 5px;
            margin-bottom: 0;
          }

          .first-line__red-text {
            color: white;
            font-weight: bold;
          }
        }

        &__second-line {
          line-height: 0.8;
        }
      }
    }

    .regular-text-button {
      height: fit-content;
      @include analysisButton;
      background-color: #1565c0;

      &:hover {
        cursor: pointer;
      }

      &__text {
        display: flex;
        flex-direction: column;

        &__first-line {
          display: flex;

          p {
            margin-left: 5px;
            margin-bottom: 0;
          }

          .first-line__blue-text {
            color: white;
            font-weight: bold;
          }
        }

        &__second-line {
          line-height: 0.8;
          margin: 0;
        }
      }
    }
  }

  &__tabel-block {
    margin-top: 40px;
    padding: 15px;
    width: 100%;
    border: 3px solid #ebebeb;
    border-radius: 25px;
    display: flex;
    align-items: center;
    flex-direction: column;

    .tabel-block__header {
      font-size: 30px;
    }
  }
}
